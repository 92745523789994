<template>
  <common title="我要兑换">
    <div class="convert-content">
      <div class="btns">
        <div class="tab">
          <button
            :class="{ btn1: true, active: currentIndex == 0 }"
            @click="toggleStatus(0)"
          >
            可兑换
          </button>
          <button
            :class="{ btn2: true, active: currentIndex == 1 }"
            @click="toggleStatus(1)"
          >
            兑换记录
          </button>
        </div>
        <div class="jifen">
          可用积分：{{ pointsDetail.availableTokenPoint || 0 }}分
        </div>
      </div>
      <div class="joinActivity">
        <div class="activity-list">
          <div
            class="list"
            v-for="(item, index) in list"
            :key="index"
            @click="handelClickDetail(item)"
          >
            <div class="item-l">
              <img :src="item.imageUrl || item.skuImageUrl" alt="" />
            </div>
            <div class="item-r" v-if="currentIndex == 0">
              <p class="name">{{ item.title }}</p>
              <p>库存：{{ item.stock }}</p>
              <p style="color: #ed0a10">
                所需积分：{{ item.pointsPrice || 0 }}积分
              </p>
            </div>
            <div class="item-r" v-else>
              <p class="name">{{ item.changeTitle }}</p>
              <p>订单号：{{ item.orderSn }}</p>
              <p>兑换数量：{{ item.exchangeGoodsNum }}</p>
              <p>兑换时间：{{ item.insertTime }}</p>
              <p>支付积分：{{ item.pointsPrice || 0 }}积分</p>
            </div>
          </div>
        </div>
        <div class="pagination">
          <van-pagination
            force-ellipses
            v-model="searchParams.curPage"
            :total-items="totals"
            :items-per-page="searchParams.pageSize"
            @change="handelChangePagination"
          >
            <template #prev-text>
              <van-icon name="arrow-left" />
            </template>
            <template #next-text>
              <van-icon name="arrow" />
            </template>
            <template #page="{ text }">{{ text }}</template>
          </van-pagination>
        </div>
      </div>
    </div>
  </common>
</template>

<script>
import Vue from "vue";
import { Pagination, Icon } from "vant";
Vue.use(Pagination);
Vue.use(Icon);
import { getPointsMallList, pointExchangeList, pointsUrl } from "./api";
import common from "../components/common.vue";
import { mapState } from "vuex";
export default {
  name: "myCovert",
  components: {
    common,
  },
  props: {},
  data() {
    return {
      currentIndex: 0,
      totals: 0, //数据总页数
      list: [], //活动数据
      searchParams: {
        curPage: 1,
        pageSize: 6,
        // userId: "1763412296209608705",
      },
      pointsDetail: {}, //积分详情
    };
  },
  created() {},
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  mounted() {
    const { currentIndex } = this.$route.query;
    if (currentIndex == 1) {
      this.currentIndex = currentIndex;
      this.getExchangeList();
    } else {
      this.getList();
    }
    this.getPoints();
  },
  methods: {
    //获取列表
    getList() {
      const params = {
        ...this.searchParams,
        userId: this.userId,
      };
      this.$axios
        .get(getPointsMallList, {
          params,
        })
        .then((res) => {
          if (res.code == 200) {
            const { records, total } = res.data;
            this.totals = total;
            this.list = records;
          }
        });
    },
    async getPoints() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      let res = await this.$axios.post(pointsUrl, this.$qs.stringify(params), {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
      this.pointsDetail = res.data;
    },
    getExchangeList() {
      const params = {
        ...this.searchParams,
        userId: this.userId,
        // status: 1,
        // userId: "1763412296209608705",
      };
      this.$axios
        .get(pointExchangeList, {
          params,
        })
        .then((res) => {
          if (res.code == 200) {
            const { records, total } = res.data;
            this.totals = total;
            this.list = records;
          }
        });
    },
    //切换显示参加状态
    toggleStatus(status) {
      this.searchParams.curPage = 1;
      this.currentIndex = status;
      this.loadList();
    },
    loadList() {
      if (this.currentIndex == 0) {
        this.getList();
      } else {
        this.getExchangeList();
      }
    },
    //切页
    handelChangePagination(value) {
      this.searchParams.curPage = value;
      this.loadList();
    },
    //跳转详情页面
    handelClickDetail(item) {
      this.$router.push({
        name: "myCovertDetail",
        query: {
          id: item.id,
          title: item.title || item.changeTitle,
          currentIndex: this.currentIndex,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.convert-content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.joinActivity {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0 26px 0;
  box-sizing: border-box;
  .activity-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .list {
      display: flex;
      width: calc((100% - 40px) / 3);
      height: 174px;
      background: #f6f7fa;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid #e4e7ed;
      position: relative;
      margin-bottom: 20px;
      &:not(:nth-child(3n)) {
        margin-right: 20px;
      }
      .status {
        position: absolute;
        right: 0;
        top: 0;
        .status-text {
          padding: 4px 10px;
          background: #1874fd;
          border-radius: 0px 4px 0px 100px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          line-height: 17px;
          text-align: center;
          font-style: normal;
          &.finish {
            color: #a7a7a7;
            background: #e6e6e6;
          }
        }
      }
      .item-l {
        flex-shrink: 0;
        width: 142px;
        height: 100%;
        padding: 12px 20px 13px 11px;
        box-sizing: border-box;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .item-r {
        width: calc(100% - 142px);
        z-index: 2;
        padding-top: 22px;
        box-sizing: border-box;
        color: #666666;
        font-size: 14px;
        p {
          margin-bottom: 5px;
        }
        .name {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          box-sizing: border-box;
          text-overflow: ellipsis;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #2f3a4f;
          line-height: 25px;
          text-align: left;
          font-style: normal;
          margin-bottom: 5px;
        }
        .active-type {
          margin: 9px 0 8px 0;
          box-sizing: border-box;
          .status-red {
            width: 60px;
            height: 19px;
            border-radius: 4px;
            border: 1px solid #ed0a10;
            font-weight: 400;
            font-size: 12px;
            color: #ed0a10;
            line-height: 19px;
            text-align: center;
          }
          .status-blue {
            width: 60px;
            height: 19px;
            border-radius: 4px;
            border: 1px solid #2a7271;
            font-weight: 400;
            font-size: 12px;
            color: #2a7271;
            text-align: center;
            line-height: 19px;
          }
        }
        .active-address {
          width: 100%;
          margin-bottom: 7px;
          white-space: nowrap;
          overflow: hidden;
          box-sizing: border-box;
          text-overflow: ellipsis;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          text-align: left;
        }
        .p-apply,
        .p-activity {
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 20px;
          position: relative;
          padding-left: 12px;
          &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background: linear-gradient(180deg, #3397fe 0%, #3666ef 100%);
            border-radius: 50%;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .p-apply {
          margin-bottom: 8px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            background: #1ebb70;
            border-radius: 50%;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
    }
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    ::v-deep .van-pagination {
      .van-pagination__item {
        min-width: 32px;
        height: 32px;
        border-radius: 8px;
        &:active {
        }
        &.van-pagination__item--disabled {
          background-color: transparent;
        }
        // color: rgba(0, 0, 0, 0.85);
        &.van-pagination__item--active {
          // color: #fff;
        }
        &::after {
          border-width: 0;
        }
      }
    }
  }
}
.jifen {
  font-size: 16px;
  color: #ed0a10;
}
.btns {
  margin: 20px 0 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  box-sizing: border-box;
  .tab {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.btns button {
  width: 83px;
  height: 100%;
  // margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #1874fd;
  font-size: 16px;
  background: #fff;
  color: #1874fd;
  cursor: pointer;
}
.btn1 {
  border-radius: 6px 0px 0px 6px;
  border-right: none !important;
}
.btn2 {
  border-radius: 0px 6px 6px 0px;
}
.btns .active {
  background: #1874fd;
  color: #fff !important;
}
</style>
